<template>
  <div>
    <v-main>
      <v-container class="d-flex justify-center">
        <v-row justify="center">
          <v-col
            cols="12"
            v-if="$route.query.pid && $route.query.cid && $route.query.at"
            xs="12"
            sm="6"
            md="6"
          >
            <h3 class="text-h5">Download Visicardia to connect with {{$route.query.name}} </h3>
            <img
              :src="imgSrc"
              alt="Card"
              style="width: 100%; max-width: 640px"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6">
            <v-card
              :elevation="0"
              class="d-flex"
              style="flex-direction: column"
              max-width="500"
            >
              <div class="mx-auto d-flex align-center justify-center">
                <v-img
                  alt="Visicardia Logo"
                  class="shrink"
                  contain
                  src="@/assets/images/visicardia-red.png"
                  transition="scale-transition"
                  width="100"
                />
                <div class="ml-3">
                  <h6
                    class="text-h6 text-uppercase mt-3 mb-0"
                    style="font-weight: 300; line-height: 1"
                  >
                    Visicardia
                  </h6>
                  <p class="my-0" style="color: #bbb">
                    <small>KEEP IN TOUCH</small>
                  </p>
                </div>
              </div>
              <v-divider class="my-3"></v-divider>
              <p>
                What is Visicardia? Visicardia is a digital business card app that allows
                users to create, customise and share unlimited business cards
                with others seamlessly, using the Visicardia app. The app is
                available for download on the iOS App Store and Android Play
                store.
              </p>
              <div class="d-md-flex align-center">
                <v-btn
                  plain
                  :href="appstoreLink"
                  style="margin-bottom: 1rem; margin-left: -15px"
                  class="download-btn"
                >
                  <img
                    src="@/assets/images/app-store.png"
                    class="w-100"
                    height="48"
                  />
                </v-btn>
                <v-btn
                  plain
                  :href="playstoreLink"
                  style="margin-bottom: 1rem; margin-left: -15px"
                  class="download-btn"
                >
                  <img
                    src="@/assets/images/play-store.png"
                    class="w-100"
                    height="48"
                  />
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar v-model="visible">
          Proceed to {{ checkStore }} Store

          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="openStore">
              Open
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </div>
</template>
<script>
export default {
  name: "DownloadVisicardia",
  data() {
    return {
      playstoreLink:
        "https://play.google.com/store/apps/details?id=com.visicardia.visicardia.dev",
      appstoreLink: "https://apps.apple.com/in/app/visicardia/id1564057901",
      visible: false,
      currentUser: [],
    };
  },
  computed: {
    checkStore() {
      if (/Android/gi.test(navigator.userAgent)) {
        return "Play";
      } else if (/iPhone|iPad/gi.test(navigator.userAgent)) {
        return "App";
      }
      return "";
    },
    imgSrc() {
      return `https://firebasestorage.googleapis.com/v0/b/visicardia-4a366.appspot.com/o/screenshot%2F${this.$route.query.pid}-${this.$route.query.cid}.png?alt=media&token=${this.$route.query.at}`;
    },
  },
  async created() {
    document.title = "Download Visicardia";
  },
  async mounted() {
    setTimeout(() => {
      this.visible = /Android|iPhone|iPad/gi.test(navigator.userAgent);
    }, 3000);
  },
  methods: {
    openStore() {
      if (/Android/gi.test(navigator.userAgent))
        location.href = this.playstoreLink;
      if (/iPhone|iPad/gi.test(navigator.userAgent))
        location.href = this.appstoreLink;
    },
  },
  beforeDetroy() {
    document.title = "Visicardia";
  },
};
</script>
<style lang="scss" scoped>
.download-btn {
  margin-bottom: 1rem;
  margin-left: -15px;
  .v-btn__content {
    opacity: 1 !important;
  }
}
</style>